import React from 'react'
import { Layout } from '../Shared'
import helmetProps from './helmetProps'
import Hero from './sections/Hero'
import TestimonialFeature from './sections/TestimonialFeature'
import CaseStudy from './sections/CaseStudy'
import TestimonialList from './sections/TestimonialList'
import Logos from './sections/Logos'
import './Customers.global.scss'
import IrisImg from '../Shared/IrisImg'

const CustomersPage = ({ width, images }) => {
  let view = 'desktop'
  if (width < 1250) view = 'tablet'
  if (width < 888) view = 'mobile'

  return (
    <div id="customers-page" className={`full-height ${view}`}>
      <Hero
        map={images.map}
        logosTop={images.heroLogosTop}
        logosBottom={images.heroLogosBottom}
        view={view}
        tiny={width < 600 ? 'tiny' : ''}
      />

      <TestimonialFeature
        {...images.testimonialFeature.gilbane}
        link="https://www.gilbaneco.com"
        quote="Prospect has been a tremendous addition to Gilbane VDC’s tool belt. Prospect helps our project teams better understand installation & sequencing requirements for complex spaces, and our designers can understand the impact of coordination issues & site constraints."
        condensed
        name="Rawle D. Sawh"
        position="Director of VDC, Gilbane"
        view={view}
      />

      <CaseStudy
        image={images.caseStudy.kane}
        title={
          view === 'desktop'
            ? [
                'How Kane',
                'used VR to Build',
                'MEP Systems',
                'Five Stories Underground',
              ]
            : [
                'How Kane Used VR to Build MEP Systems Five Stories Underground',
              ]
        }
        link="https://blog.irisvr.com/how-kane-used-vr-to-build-new-mep-systems"
        view={view}
      />

      <TestimonialList images={images.testimonialList} view={view} />

      <CaseStudy
        image={images.caseStudy.shop}
        title={
          view === 'desktop'
            ? ['SHoP uses IrisVR to', 'help make design', 'decisions faster']
            : ['SHoP uses IrisVR to help make design decisions faster']
        }
        link="https://blog.irisvr.com/shop-architects-vr-case-study"
        view={view}
        inverted
      />

      {/* <Logos images={images.logos} view={view} /> */}

      <TestimonialFeature
        {...images.testimonialFeature.thornton}
        link="https://www.thorntontomasetti.com/"
        quote="IrisVR is our primary VR tool."
        name="Vladimir Gluzov"
        position="Senior Project Engineer, Thornton Tomasetti"
        view={view}
      />

    </div>
  )
}

export default props => (
  <Layout>
    <CustomersPage {...props} />
  </Layout>
)

export { helmetProps }
